* {

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 20px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #a1a5b7 !important;
    border-radius: 20px;
    border: 6px solid transparent !important;
    background-clip: content-box !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf !important;
  }
}

.schedule-item {
  overflow-y: scroll;
  scroll-behavior: smooth;
  border-top: 1px solid;
}

.back-ground-grey {
  color: #fff !important;
  background-color: #bfbfbf !important;
}

.back-ground-green {
  color: #fff !important;
  background-color: #17DFA9 !important;
}

.back-ground-light-blue {
  color: #fff !important;
  background-color: #9CCFBA !important;
}

.back-ground-blue {
  color: #fff !important;
  background-color: #05B2AC !important;
}

.back-ground-delete {
  color: #fff !important;
  background-color: #D24646 !important;
}

.button-add-new {
  width: 170px;
  height: 40px;
  font-size: 14px;
}

.schedule {
  .nav-pills .nav-link.active {
    background-color: green !important;
  }

  .re-assign {
    color: #fff;
    background-color: grey !important;
  }
}

.popup-warning {
  box-shadow: none;
  width: 50%;
}


.has-search {}

.has-search .form-control {
  padding-left: 2.375rem;
  border-radius: 10px !important;
}

.has-search .spinner-border {
  margin-top: 13px;
  position: absolute;
  z-index: 2;
  display: block;
  width: 1.375rem;
  height: 1.375rem;
  line-height: 45px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 45px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.active-day {
  color: #fff;
  background-color: #009ef7;
}

.hidden {
  display: none !important;
}

.van-active {
  background-color: #17DFA9;
  color: #fff;
  padding: 8px;
  // border-radius: 5px;
}

.vanId-scroll {
  width: 70%;
  white-space: nowrap;
  overflow-x: scroll !important;
  height: 36px;
  padding-top: 6px;
}

.form-select-ben {
  width: 50%;
  padding: 5px !important;
  margin-bottom: 5px !important;
}

.style-images {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-left: 10px;
  box-shadow: 0 0 10px 0;
}

.style-notes {
  max-width: 550px;
  max-height: 200px;
  overflow: hidden;
  margin-bottom: 5px;
  overflow-y: auto;
}

.van-scroll {
  max-width: 896px;
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

#Icon_metro-profile-2 {
  fill: #fff;
}

.hidden-note {
  opacity: 0;
}

.question-tooltips {

  background-color: #333;

  color: white;

  max-width: 100% !important;

  margin-left: auto !important;

  word-break: break-word !important;
}

.calendar-history>.react-calendar {
  width: 500px;
  max-width: 100%;
  background: white;
  border: none;
  font-family: Poppins, Helvetica, "sans-serif";
  // line-height: normal;
}

.table-responsive {
  overflow-x: visible !important;
}

@media (max-width: 1600px) {
  #kt_post {
    // width: fit-content !important;
  }
}

@media (min-width: 1000px) {
  #kt_header {
    display: none !important;
  }
}

.css-van {
  border: 1px solid black;
  padding: 5px;
}

.van-item {
  width: 100px;
  text-align: center;
  overflow: hidden;
}